// 个人中心 // 播放历史
<template>
  <div id="app3">
    <!-- 顶部 -->
    <Top ref="top" :white="$store.state.model.black"></Top>

    <div class="history_body">
      <!-- 左侧 -->
      <Leftside></Leftside>
      <!-- 右侧主题 -->
      <!-- <transition name="RightToleft"> -->
      <div class="his_r_box">
        <div class="history_body_right2" v-show="enter">
          <div class="his_r_title">
            <span>播放记录</span>
            <div class="edit_right">
              <p v-show="editShow" class="his_edit-1" @click="delhisEdit()">
                删除选中( {{ editList.length }} )
              </p>
              <p v-show="!editShow && playList" class="his_edit" @click="hisEdit(false)">
                批量编辑
              </p>
              <p v-show="editShow" class="his_edit-2" @click="hisEdit(true)">取消编辑</p>
            </div>
          </div>

          <!-- 历史播放主体 -->
          <transition name="RightToleft">
            <div class="his_r_body his_r_body2 his_r_body3" v-show="enter">
              <None v-if="!playList"></None>
              <!-- 遍历循环的每一个模块 -->
              <div class="his_new" v-for="(item, index) in playList" :key="index">
                <div class="his_nleft" :class="{ 'his_nleft-hover': !editShow }">
                  <img
                    :src="item.video_pic ? item.video_pic : ''"
                    :onerror="$store.state.defaultimg"
                    alt="历史记录视频的封面并点击前往播放"
                    @click="goto(item)"
                  />
                  <!-- 电视剧样式 -->
                  <div class="is_series" v-show="item.multiple_fragment">
                    <img class="ab1" src="../assets/img/back_blue2.png" alt="背景样式" />
                    <img class="ab2" src="../assets/img/back_blue.png" alt="背景样式" />
                  </div>
                  <div class="his_tips" v-show="!item.multiple_fragment && item.video_duration">
                    <!-- 视频总时长 -->
                    <span>{{ YS.tomin(item.video_duration) }}</span>
                  </div>
                  <div class="his_tips" v-show="item.multiple_fragment">
                    <!-- 视频总集数 -->
                    <span v-show="item.video_state == '更新中'">
                      更新至{{ item.last_fragment_symbol }}集
                    </span>
                    <span v-show="item.video_state == '已完结'">
                      {{ item.last_fragment_symbol }}集全
                    </span>
                  </div>
                  <!-- {{ editList.filter((itemindex) => itemindex === index) }} -->
                </div>
                <div class="his_nright">
                  <span class="histitle" @click="goto(item)" :title="item.video_title">
                    {{ item.video_title }}
                  </span>
                  <span class="hisnodes hisn1" :title="item.video_director">
                    导演：{{ item.video_director }}
                  </span>
                  <span class="hisnodes hisn2" :title="item.video_starring">
                    主演：{{ item.video_starring }}
                  </span>
                  <div class="hislist_bottom—out">
                    <span class="hislist_bottom—out-l">
                      <span>观看至</span>
                      <span v-if="item.multiple_fragment">
                        第{{ item.video_fragment_symbol }}集
                      </span>
                      <span v-if="item.video_duration && item.elapsed">
                        {{ Math.floor((item.elapsed * 100) / item.video_duration) }}%
                      </span>
                      <span v-if="!item.video_duration || !item.elapsed">0%</span>
                    </span>
                    <span @click="delOne(item.id)" class="del_span-ml">删除</span>
                  </div>
                </div>
                <div
                  class="his_edit_box"
                  :class="{
                    edit_black: editList.filter((itemindex) => itemindex === index).length,
                  }"
                  v-show="editShow"
                  @click="checkEdit(item, index)"
                >
                  <div
                    class="his_edit_list"
                    :class="{
                      'his_edit_list-check': editList.filter((itemindex) => itemindex === index)
                        .length,
                      'his_edit_list-uncheck': !editList.filter((itemindex) => itemindex === index)
                        .length,
                    }"
                  ></div>
                  <!-- <div
                      class="his_edit_list his_edit_list-check"
                      @click="checkEdit(item, index)"
                    ></div> -->
                </div>
              </div>
              <!-- //挡住下划线的 -->
              <div class="block_hisbottom"></div>

              <!-- ------------------------------------- -->
              <!-- <div
                class="his_r_list"
                v-for="(item, index) in playList"
                :key="index"
                @click="goto(item.id)"
              >
                <div class="his_rl_imgdiv"><img :src="item.video_pic" /></div>
                <div class="his_rl_title">
                  <a>{{ item.video_title }}</a>
                </div>
                <div class="his_rl_nodes">
                  <img src="../assets/img/play2.png" />
                  <span>{{ item.video_appreciate }}</span>
                </div>
              </div> -->
            </div>
          </transition>
        </div>
        <!-- 分页 -->
        <div class="pagination" v-show="fy.pageEnter && fy.pagenum > 1">
          <div class="pagebody">
            <div class="page_left" @click="YS.pagebutton('left', fy, getPlaylist)">
              <img src="../assets/img/left.png" alt="上一页" />
            </div>
            <div class="page_cent">
              <div
                class="page_num"
                v-for="(item, index) in fy.pageList"
                :key="index"
                @click="YS.clickPage(item, fy, getPlaylist)"
                :class="{ 'page_num-act': fy.pages.page == item }"
              >
                <a>{{ item }}</a>
              </div>
            </div>

            <div class="page_right" @click="YS.pagebutton('right', fy, getPlaylist)">
              <img src="../assets/img/right.png" alt="下一页" />
            </div>
            <div class="page_total">
              <input
                class="fenye_input"
                v-model="fy.jump"
                @focus="YS.getInputFocus($event)"
                @keyup.enter="YS.clickPage(fy.jump, fy, getPlaylist)"
              />
            </div>
            <div class="page_total">
              <span>共{{ fy.pagenum }}页</span>
            </div>
          </div>
        </div>
      </div>
      <!-- </transition> -->
    </div>
  </div>
</template>
<script>
let that;
let fetch;
import Top from '../components/Top'; //导航top
import Leftside from '../components/Leftside'; //左侧主题
import None from '../components/None'; //无内容
export default {
  components: {
    Top,
    Leftside,
    None,
  },
  name: 'History',
  data() {
    return {
      editList: [],
      hisIDList: [],
      editShow: false,
      his_show: true,
      enter: false,
      leftListcss: {}, //左下列表

      src: require('../assets/text/m2.png'),
      playList: [],
      //分页用
      fy: {
        pageEnter: false, //第一次计算完返回true
        pagenum: null,
        pagetotal: 20,
        pages: {
          page: 1,
          pagesize: 12,
        },
        jump: null,
        mobanpage: [1, 2, 3, 4, 5, 6, 7, 8],
        pageList: [1, 2, 3, 4, 5, '...', 11, 12],
      },
    };
  },
  created() {
    that = this;
    fetch = this.api.user;
    fetch;
    //var ismob = /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent);
    that.YS.toMobile('/history');
  },
  beforeMount() {},
  mounted() {
    that.enter = true;
    that.YS.closeallWin(); //关闭所有弹窗
    that.YS.actlist(that.$store.state.leftListcss, 0, 'history_lc_list_act'); //添加默认选中样式
    that.YS.iconAct(that.$store.state.leftComponent, 0); //添加默认选中样式
    that.getPlaylist();
  },
  methods: {
    //删除一个
    async delOne(id) {
      await that.api.user.api_useremptyVideoHistory({
        selected: 1,
        ids: id,
      });
      that.getPlaylist();
      that.$refs.top.messTop = '删除成功';
      that.$refs.top.mess_show = true;
      setTimeout(() => {
        that.$refs.top.mess_show = false;
      }, 3000);
    },
    delhisEdit() {
      if (!that.editList.length) {
        that.$refs.top.messTop = '请选择删除目标';
        that.$refs.top.mess_show = true;
        setTimeout(() => {
          that.$refs.top.mess_show = false;
        }, 3000);
        return;
      }
      that.$refs.top.smalltype = 'edit';
      that.YS.gotoVip({}, 'open', 'small');
    },
    //编辑选中
    checkEdit(item, index) {
      if (!that.editList.filter((itemindex) => itemindex === index).length) {
        that.editList.push(index);
        that.hisIDList.push(item.id);
      } else {
        that.editList.splice(that.editList.indexOf(index), 1);
        that.hisIDList.splice(that.editList.indexOf(item.id), 1);
      }
    },
    //点击编辑
    hisEdit(type) {
      if (!type) {
        //点击编辑
        that.editShow = true;
      } else {
        //点击取消编辑
        that.editShow = false;
        that.editList = [];
        that.hisIDList = [];
      }
    },
    // 跳转指定播放页
    goto(item) {
      that.YS.routetogo(
        {
          path: '/play',
          // query: { video_id: item.video_id, seek: item.elapsed, lid: item.video_fragment_symbol },
          query: { video_id: item.video_id, lid: item.video_fragment_id, seek: item.elapsed },
        },
        true,
      );
    },
    //获取播放历史记录列表
    getPlaylist() {
      fetch.api_videoHistory(that.fy.pages).then((data) => {
        that.enter = false;
        that.playList = data.video_history_list;
        that.fy.pagetotal = data.video_history_total;
        that.YS.jilu(that.fy);
        setTimeout(() => {
          that.enter = true;
        });
        // this.$nextTick(() => {
        //   that.enter = true;
        // });
        // console.log(data);
      });
    },
  },
};
</script>
<style lang="scss"></style>
